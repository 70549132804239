type CalendarInfosTermListItemTypes = {
    termIndex: number,
    term: string,
    selectedTerm: number,
    setSelectedTerm: Function,
}

const CalendarInfosTermListItem: React.FC<CalendarInfosTermListItemTypes> = ({termIndex, term, selectedTerm, setSelectedTerm}) => {
    return (
        <div className={`calendar__infos__termList__item ${selectedTerm === termIndex ? 'calendar__infos__termList__item--selected' : ''}`} onClick={() => setSelectedTerm(termIndex)}>{term}</div>
    )
}

export default CalendarInfosTermListItem;