import CalendarDays from "./CalendarDays";
import CalendarInfos from "./CalendarInfos";
import {getNumbericTime, getTimeDiff} from "../Base/functions";
import {useEffect, useState} from "react";
import {config} from "../Base/config";

type appoType = {
    day: number;
    start: String;
    end: String;
    label: String;
    type: String;
}

type ImportApposType = {
    [key: number]: {
        term: string;
        appos: appoType[];
    };
};

const CalendarContainer = () => {
    const startHour = 6;
    const [selectedTerm, setSelectedTerm] = useState<number | null>(null); //Auto-select last term
    const [importAppos, setImportAppos] = useState<ImportApposType | null>(null);

    useEffect(() => {
        fetch(`${config.baseURL}importData.php`)
            .then((res) => res.json())
            .then((json) => {
                setImportAppos(json);
                setSelectedTerm(Object.keys(json).length);
            })
            .catch((err) => console.error("Error: ", err));
    }, [])

    if (!importAppos || selectedTerm === null) return;

    const selectedAppo = importAppos[selectedTerm]?.appos || [];
    let termList: { [key: number]: { term: string } } = {};

    for (let i in importAppos) {
        termList[+i] = {term: importAppos[i]?.term};
    }

    let appos: any[] = [];
    for (let i in selectedAppo) {
        appos[i] = {
            ...selectedAppo[i],
            start: {
                string: selectedAppo[i].start,
                hour: getNumbericTime(selectedAppo[i].start, 0),
                minute: getNumbericTime(selectedAppo[i].start, 1),
                minutePercent: getNumbericTime(selectedAppo[i].start, 1) / 0.6,
            },
            end: {
                string: selectedAppo[i].end,
                hour: getNumbericTime(selectedAppo[i].end, 0),
                minute: getNumbericTime(selectedAppo[i].end, 1),
                minutePercent: getNumbericTime(selectedAppo[i].end, 1) / 0.6,
            },
            height: ((getNumbericTime(selectedAppo[i].end, 0) - getNumbericTime(selectedAppo[i].start, 0)) * 100) + ((getNumbericTime(selectedAppo[i].end, 1) / 0.6) - (getNumbericTime(selectedAppo[i].start, 1) / 0.6)),
            sum: getTimeDiff(selectedAppo[i].start, selectedAppo[i].end),
        }
    }


    return (
        <div className="calendar">
            <div className="calendar__container">
                {Array.from({length: 8}, (_, index) => index).map((day, i) => (
                    <CalendarDays
                        key={i}
                        day={day}
                        appos={appos}
                        startHour={startHour}
                    />
                ))}
            </div>
            <CalendarInfos
                appos={appos}
                termList={termList}
                selectedTerm={selectedTerm}
                setSelectedTerm={setSelectedTerm}
            />
        </div>
    )
}

export default CalendarContainer;