import React from "react";
import {config} from "../Base/config";
import CalendarInfosTermListItem from "./CalendarInfosTermListItem";

type appoTypes = {
    day: number;
    start: String;
    end: String;
    label: String;
    type: String;
    height: number;
    sum: number;
}

type termListTypes = {
    [key: number]: {
        term: string;
    }
}

type CalendarInfosTypes = {
    appos: appoTypes[];
    termList: termListTypes,
    selectedTerm: number,
    setSelectedTerm: Function,
}

const CalendarInfos: React.FC<CalendarInfosTypes> = ({appos, termList, selectedTerm, setSelectedTerm}) => {
    let types:Array<String> = [];
    for(let i in appos) {
        if(!types.includes(appos[i].type)) {
            types.push(appos[i].type);
        }
    }

    const sums:{[key: string]: number} = {};
    const skipTypes = ['uni-skip','uni-not']; //Types to skip at total

    for(let type in types) {
        let sum = 0;
        for(let i in appos) {
            if(appos[i].type === types[type]) {
                sum += appos[i].sum;
            }
        }
        sums[types[type].toString()] = sum;
    }

    for(let i in sums) {
        if(skipTypes.includes(i)) continue;
        if(!sums['total']) sums['total'] = 0;
        sums['total'] += sums[i];
    }

    return (
        <div className="calendar__infos">
            <div className="calendar__infos__logo">
                <img src={`${config.baseURL}/src/Base/Media/Images/Uni_Logo_2016_SW.png`} alt="Uni Logo"/>
            </div>
            <div className="calendar__infos__termList">
                <div className="calendar__infos__termList__header">
                    Terms:
                </div>
                {Object.keys(termList).map((term) => (
                    <CalendarInfosTermListItem
                        key={+term}
                        termIndex={+term}
                        term={termList[+term].term}
                        selectedTerm={selectedTerm}
                        setSelectedTerm={setSelectedTerm}
                    />
                ))}
            </div>
            <div className="calendar__infos__infoContainer">
                {Object.keys(sums).map((item,index) => (
                    <div className="calendar__infos__info" key={index}>
                        <div className="flex flex--5">
                            <div className={`calendar__infos__info__color ${item}`}></div>
                            <div className="calendar__infos__info__label capitalize">{item}:</div>
                        </div>
                        <div className={item === "total" ? "calendar__infos__info__sum calendar__infos__info__sum--total" : "calendar__infos__info__sum"}>{sums[item]}h</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CalendarInfos;